<template>
    <FilterBlock :title="filter.label">
        <BaseUiRange
            v-model="range"
            :min="filter.additionalProps?.min ?? 0"
            :max="filter.additionalProps?.max ?? 0"
            :loading="loading"
            @change="handleFilterChange"
        />

        <div class="mt-2 flex flex-row justify-between gap-4">
            <span>
                {{ range[0] }}
                {{ currencySymbol }}
            </span>

            <span>
                {{ range[1] }}
                {{ currencySymbol }}
            </span>
        </div>
    </FilterBlock>
</template>

<script lang="ts" setup>
const {
    filter,
    loading,
} = defineProps<{
    filter: InstanceType<typeof ProductFilterModel>
    loading?: boolean
}>()

const { currencySymbol } = useProperties()
const range = ref<[number, number]>([filter.additionalProps?.current_min ?? 0, filter.additionalProps?.current_max ?? 0])
let wasForceChanged = false
watch(() => filter, () => {
    wasForceChanged = true
    range.value = [filter.additionalProps?.current_min ?? 0, filter.additionalProps?.current_max ?? 0]
})

const emit = defineEmits<{
    change: [url: string]
}>()

function handleFilterChange() {
    if (wasForceChanged) {
        wasForceChanged = false
        return
    }
    const url = filter.getRangeUrl(range.value)
    if (!url) return

    emit('change', url)
}
</script>
