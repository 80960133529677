<script lang="tsx">
import defineComponentCoreUiColorSwatchSelector from '@core/app/components/core/ui/CoreUiColorSwatchSelector.vue'

export default defineComponentCoreUiColorSwatchSelector({
    slots: {
        checkbox: ({ isSelected }) => isSelected
            ? <svg height="8" viewBox="-2 -1 13 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.99999 8.4375L0.312988 4.6875L1.68799 3.3125L3.99999 5.5625L9.31299 0.3125L10.688 1.6875L3.99999 8.4375Z"
                />
            </svg>
            : null,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiSelector" as *;
@use "@core-scss/components/CoreUiColorSwatchSelector" as *;

@include wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

@include checkbox {
    width: 1.625rem;
    height: 1.625rem;

    border: 2px solid $vut-c-white;
    border-radius: $vut-border-radius;
    background-color: white;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: $vut-shadow-sm;

    &:focus-within {
        border-radius: 0 !important;
    }
}

@include checkbox--focus-visible {
    border-radius: 0 !important;
}

@include checkbox--checked {
    color: $vut-c-white;
    border-color: $vut-c-white;
}

@include checkbox-background {
    position: absolute;
    inset: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
}
</style>
