<template>
    <BaseUiSelect
        v-model="selectedOption"
        :label="`${$t('filters.sort_by')}:`"
        :options="optionsForSelect"
        class="!w-auto"
    />
</template>

<script lang="ts" setup>
import type { ProductFiltersResponseModel } from '@simploshop-models/product-filters-response.model'
import type { SelectOption } from '@core/app/components/base/ui/select/BaseUiSelect.vue'
import type { ApiModelAttributes } from '@composable-api/api.model'

const {
    options,
} = defineProps<{
    options: ApiModelAttributes<ProductFiltersResponseModel>['sort_options']
}>()

const emit = defineEmits<{
    change: [url: string]
}>()

const optionsForSelect = computed<SelectOption<string>[]>(() => {
    return options.map(option => [
        {
            label: `${option.label} ↑`,
            value: option.asc_url,
        },
        {
            label: `${option.label} ↓`,
            value: option.desc_url,
        },
    ]).flat()
})

const selectedOption = computed({
    get() {
        const selectedOptionFromApi = options.find(option => option.is_selected)
        if (!selectedOptionFromApi) return null

        // get the value of the option that matches the selected option from the api
        return optionsForSelect.value.find(option => option.value === (
            selectedOptionFromApi.selected_direction === 'asc'
                ? selectedOptionFromApi.asc_url
                : selectedOptionFromApi.desc_url
        ))?.value ?? null
    },
    set(val) {
        if (!val) return
        emit('change', val)
    },
})

</script>

<style lang="scss" scoped>

</style>
