<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <LayoutComponent
        aside
        :collapsible-categories="!areNoResults || !!selectedFilters.length"
    >
        <template #aside>
            <!--  FILTERS  -->
            <FilterPanel
                v-if="filtersResponse?.getItem()?.isFilterPanelVisible() || filtersResponse?.getItem()?.getSelectedFilters().length"
                class="vut-border-bottom vut-border-right mb-8 px-5 pt-9"
                :filters="filtersResponse?.getItem()?.getFilterBlocks() ?? []"
                :selected-filters="selectedFilters"
                :loading="areFiltersLoading"
                @change="handleFiltersChange"
            />
        </template>

        <!-- CATEGORY INFO -->
        <CoreContainer>
            <BaseContainerContent :padding="{ right: 'normal-only', vertical: ['large', 'lg:default'], left: 'lg:small-only' }">
                <!--  FILTERS BUTTON (mobile)  -->
                <BaseUiButton
                    v-if="filtersResponse?.getItem()?.isFilterPanelVisible() || filtersResponse?.getItem()?.getSelectedFilters().length"
                    class="mb-12 w-full lg:hidden"
                    color="white"
                    variant="outlined"
                    align="between"
                    @click="isFilterDrawerOpen = true"
                >
                    <template #leading>
                        <IconSettings :size="20" />
                    </template>
                    <span class="mr-auto">
                        {{ `${$t('labels.to_filter')}${filtersResponse?.getItem()?.getSelectedFilters().length ? ` (${filtersResponse?.getItem()?.getSelectedFilters().length})` : ''}` }}
                    </span>
                    <template #trailing>
                        <IconChevron class="text-blue" :size="10" />
                    </template>
                </BaseUiButton>

                <h1 class="vut-h1 mb-6">
                    {{ title }}
                </h1>

                <slot />
            </BaseContainerContent>
        </CoreContainer>

        <!-- CATEGORY PRODUCTS -->
        <CoreContainer>
            <BaseContainerContent
                ref="productsListWrapperEl"
                class="vut-scroll-margin"
                :padding="{ top: 'none', right: 'normal-only', bottom: 'large', left: 'lg:small-only' }"
            >
                <template v-if="!areNoResults">
                    <div class="flex flex-wrap items-center justify-between gap-3">
                        <UiPaginationInfo
                            v-if="filtersResponse?.getItem()?.paginationData"
                            t="filters.products_total"
                            type="total"
                            class="flex-1"
                            :pagination="filtersResponse!.getItem()!.paginationData!"
                        />

                        <UiPaginationCount
                            v-if="filtersResponse?.getItem()?.paginationData"
                            :pagination="filtersResponse!.getItem()!.paginationData!"
                            class="!hidden md:!flex"
                            @change="handlePerPageChange"
                        />

                        <FilterSortSelect
                            v-if="filtersResponse?.getItem()?.sortOptions"
                            :options="filtersResponse!.getItem()!.sortOptions!"
                            @change="handleSortChange"
                        />
                    </div>

                    <ProductList>
                        <ProductCard
                            v-for="product in filtersResponse?.getItem()?.getProducts()"
                            :key="product.id!"
                            class="!w-full"
                            :product="product"
                            :loading="areFiltersLoading || areProductsLoading"
                        />
                    </ProductList>

                    <div class="mt-10 flex flex-col items-center justify-center gap-3 md:flex-row md:justify-between">
                        <BaseUiButton
                            v-if="!filtersResponse?.getItem()?.paginationData?.hasReachedLastPage()"
                            color="secondary"
                            :on-click="handleShowMoreItems"
                        >
                            {{ $t('filters.show_more_products') }}
                        </BaseUiButton>

                        <UiPaginationInfo
                            v-if="filtersResponse?.getItem()?.paginationData"
                            class="hidden flex-1 text-center md:block"
                            t="filters.products_range"
                            :pagination="filtersResponse!.getItem()!.paginationData!"
                        />

                        <BaseUiPagination
                            :aria-label="$t('accessibility.pagination')"
                            :number-of-pages="filtersResponse?.getItem()?.paginationData?.pages"
                            :current-page="filtersResponse?.getItem()?.paginationData?.page"
                            @change="handlePaginationChange"
                        />
                    </div>
                </template>

                <!--  No results found  -->
                <BlockInfoComponent
                    v-else
                    :title="noResultsMessages.title"
                    :subtitle="noResultsMessages.subtitle"
                >
                    <template v-if="type === 'category'">
                        <ul class="vut-ul mb-7">
                            <li>
                                {{ $t('messages.category.use_search').toLocaleLowerCase($i18n.locale) }}
                            </li>
                            <li>
                                {{ $t('messages.category.change_filter').toLocaleLowerCase($i18n.locale) }}
                            </li>
                            <li>
                                {{ $t('messages.category.browse_catalog').toLocaleLowerCase($i18n.locale) }}
                            </li>
                        </ul>
                    </template>
                </BlockInfoComponent>
            </BaseContainerContent>
        </CoreContainer>

        <LazyBaseSideDrawer
            v-if="wasFilterDrawerMounted"
            v-model="isFilterDrawerOpen"
            size="sm"
        >
            <!--  HEADER  -->
            <template #header>
                <UiModalHeader :title="$t('labels.filters')" />
            </template>
            <FilterPanel
                class="vut-filter-drawer__panel"
                :filters="filtersResponse?.getItem()?.getFilterBlocks() ?? []"
                :selected-filters="selectedFilters"
                :loading="areFiltersLoading"
                @change="handleFiltersChange"
            />
            <template #footer>
                <BaseUiButton class="w-full" @click="isFilterDrawerOpen = false">
                    {{ $t('filters.apply_and_show_results') }}
                </BaseUiButton>
            </template>
        </LazyBaseSideDrawer>
    </LayoutComponent>
</template>

<script lang="ts" setup>
import type { ApiResponse } from '@composable-api/api.response'
import type { AsyncData } from '#app'

const {
    title,
    filtersResponse,
    refreshFilters,
    type,
    loading,
} = defineProps<{
    title: string
    filtersResponse: ApiResponse<ProductFiltersResponseModel> | undefined
    refreshFilters: AsyncData<any, any>['refresh']
    type?: 'category' | 'search',
    loading?: boolean
}>()

const route = useRoute()
const router = useRouter()
const modelValue = defineModel<string>()

const { is: isFilterDrawerOpen, was: wasFilterDrawerMounted } = useLazyMount()

const { currencySymbol } = useProperties()

const isOverLgBreakpoint = useScssBreakpoints().greaterOrEqual('lg')
watch(isOverLgBreakpoint, () => {
    if (isOverLgBreakpoint.value) {
        isFilterDrawerOpen.value = false
    }
})

const areNoResults = computed(() => !filtersResponse?.getItem()?.getProducts().length)

const { t } = useI18n()
const noResultsMessages = computed(() => {
    if (type === 'category') {
        return {
            title: t('messages.category.no_products'),
            subtitle: t('messages.what_next'),
        }
    }

    if (type === 'search') {
        return {
            title: t('search.no_products_found'),
            subtitle: t('search.search_results_page_no_results_message'),
        }
    }

    return {
        title: t('labels.no_results'),
        subtitle: undefined,
    }
})

const selectedFilters = computed(() =>
    filtersResponse?.getItem()?.getSelectedFilters({
        currencySymbol: currencySymbol.value,
    }) ?? []
)

// re-fetch products on navigation in history
watch(() => route.fullPath, (newVal) => {
    if (newVal === modelValue.value) return
    handleFiltersChange(newVal)
})

const productsListWrapperEl = useTemplateRef<ComponentPublicInstance>('productsListWrapperEl')

const areFiltersLoading = ref<boolean>(false)
async function handleFiltersChange(url: string) {
    areFiltersLoading.value = true
    await _applyFilters(url)
    areFiltersLoading.value = false
}

async function handlePerPageChange(url: string) {
    _areProductsLoading.value = true
    await _applyFilters(url)
    _areProductsLoading.value = false
}

async function handleSortChange(url: string) {
    _areProductsLoading.value = true
    await _applyFilters(url)
    _areProductsLoading.value = false
}

/**
 * Sets the new url to filter the products with.
 * Also updates the url in the browser.
 *
 * !! THIS METHOD IS NOT MEANT TO BE CALLED FROM THE UI !!
 *
 * @param url the new url to filter with
 */
async function _applyFilters(url: string) {
    modelValue.value = url
    await refreshFilters()
    // redirect to the URI from BE if available (to hide `?page=1`, for example)
    await router.replace(filtersResponse?.getItem()?.requestUri ?? url)
}

const _areProductsLoading = ref<boolean>(false)
const areProductsLoading = computed(() => _areProductsLoading.value || loading)
async function handlePaginationChange(newPage: number) {
    const newUrl = filtersResponse?.getItem()?.paginationData?.getPageUrl(newPage)
    if (!newUrl) return
    _areProductsLoading.value = true
    await _applyFilters(newUrl)
    _areProductsLoading.value = false
    productsListWrapperEl.value?.$el.scrollIntoView({ behavior: 'smooth' })
}


// FETCH MORE ITEMS
async function handleShowMoreItems() {
    const newUrl = filtersResponse?.getItem()?.paginationData?.getNextPageRangeUrl()
    if (!newUrl) return
    await _applyFilters(newUrl)
}

</script>

<style scoped lang="scss">
</style>
