<template>
    <FilterBlock :title="filter.label" content-class="flex flex-col gap-3">
        <a v-for="filterItem in filterItemsToDisplay"
           :key="filterItem.id!"
           :href="filterItem.url!"
           class="hidden"
        >
            {{ filterItem.label }}
        </a>

        <BaseUiFormCheckbox
            v-for="filterItem in filterItemsToDisplay"
            :key="filterItem.id!"
            :value="filterItem.isSelected!"
            @change="$emit('change', filterItem.url!)"
        >
            <!-- TODO refactor BE, id is not unique -->
            <span :style="{ backgroundColor: filter.id === AUTO_GENERATED_BRAND_FILTER_ID ? getFacultyColor(filterItem.label) : undefined }"
                  :class="{ 'p-1 leading-none text-white': filter.id === AUTO_GENERATED_BRAND_FILTER_ID && !!getFacultyColor(filterItem.label) }"
            >
                {{ filterItem.label }}
            </span>
        </BaseUiFormCheckbox>
    </FilterBlock>
</template>

<script lang="ts" setup>
const {
    filter,
} = defineProps<{
    filter: InstanceType<typeof ProductFilterModel>
}>()

const AUTO_GENERATED_BRAND_FILTER_ID = 'filter-brand'

const emit = defineEmits<{
    change: [url: string]
}>()

const filterItemsToDisplay = computed(() => {
    if (!Array.isArray(filter.items)) return []

    return filter.items?.filter(item => item.shouldBeVisible()) ?? []
})

</script>

<style lang="scss" scoped>

</style>
