<template>
    <div :class="{ 'vut-filters--loading': loading }">
        <div v-if="selectedFilters?.length" class="mb-10 flex flex-wrap items-start gap-2">
            <div v-for="item in selectedFilters"
                 :key="item.id!"
                 class="vut-h6 vut-selected-filter"
            >
                {{ item.label }}

                <BaseUiButton size="auto"
                              type="button"
                              color="none"
                              class="!-m-2 !p-2"
                              @click="handleFilterChange(item.url)"
                >
                    <template #trailing>
                        <IconCross :size="10" />
                    </template>
                </BaseUiButton>
            </div>
        </div>

        <!-- TODO refactor BE, filter.id is not unique -->
        <div v-for="filter in filters"
             :key="filter.id!"
        >

            <FilterBlockRange
                v-if="filter.isRangeType()"
                :filter="filter"
                :loading="loading"
                @change="handleFilterChange"
            />

            <FilterBlockColor
                v-if="filter.isColorType()"
                :filter="filter"
                @change="handleFilterChange"
            />

            <FilterBlockCheckbox
                v-if="filter.isCheckboxType()"
                :filter="filter"
                @change="handleFilterChange"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { SelectedFilter } from '@simploshop-models/product-filters-response.model'

const {
    filters,
    loading,
    selectedFilters,
} = defineProps<{
    filters: InstanceType<typeof ProductFilterModel>[]
    loading: boolean
    selectedFilters?: SelectedFilter[]
}>()

const emit = defineEmits<{
    change: [url: string]
}>()

function handleFilterChange(url: string) {
    emit('change', url)
}

</script>

<style lang="scss" scoped>
.vut-filters--loading {
    opacity: 0.5;
    cursor: wait;

    & * {
        pointer-events: none;
    }
}

.vut-selected-filter {
   display: flex;
   align-items: center;
   gap: 0.625rem;

   padding: 0.5rem 0.625rem;

   width: fit-content;

   color: $vut-c-white;
   background: $vut-c-gray;

   border-radius: $vut-border-radius;
}
</style>
