<template>
    <BaseUiSelect
        v-model="selectedOption"
        :options="optionsForSelect"
        :placeholder="`${selectedOption}`"
        class="!w-auto"
    />
</template>

<script lang="ts" setup>
import type { SelectOption } from '@core/app/components/base/ui/select/BaseUiSelect.vue'
import type { ProductFiltersPaginationModel } from '@simploshop-models/product-filters-pagination.model'

const {
    pagination,
} = defineProps<{
    pagination: InstanceType<typeof ProductFiltersPaginationModel>
}>()

const emit = defineEmits<{
    change: [url: string]
}>()

const optionsForSelect = computed<SelectOption<number>[]>(() =>
    pagination.perPageOptions?.map(option => ({
        label: option.toString(),
        value: option,
    })) ?? []
)

const selectedOption = computed({
    get() {
        return pagination.perPage ?? null
    },
    set(val) {
        if (!val) return
        const url = pagination.getPerPageUrl(val)
        if (!url) return
        emit('change', url)
    },
})

</script>

<style lang="scss" scoped>

</style>
