<script lang="tsx">
import { defineComponentBaseUiRange } from '@core/app/components/base/ui/BaseUiRange.vue'

export default defineComponentBaseUiRange()

</script>

<style lang="scss" module>
@use "@core-scss/components/BaseUiRange" as *;

@include wrapper {
    height: 0.0625rem;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        inset: -0.5rem 0;
        pointer-events: auto;
    }
}

@include set-track-background-color($vut-c-platinum);
@include set-track-range-color($vut-c-border);

@include thumb {
    width: 1rem;
    height: 1rem;

    background-color: $vut-c-gray;
    border-radius: 100vmax;
}

</style>
